import log from "loglevel";

export const FALLBACK_LANGUAGE = "en";
export const AVAILABLE_LANG = ["en", "fr", "de"];

export const hasLocale = (path) => /^\/(en|fr|de)\b/.test(path);

export const createLocaleLink = (locale, path) => {
    if (path && path.startsWith("/")) {
        throw new Error("createLocaleLink path should not start with '/'");
    }
    if (!locale) {
        return `/${FALLBACK_LANGUAGE}/${path}`;
    }
    return `/${locale}/${path}`;
};

export const getPathWithoutLocale = (path) => {
    if (!path.startsWith("/")) {
        log.error("Provided path is a url not a valid path");
        // I am returning `/` so that the user will be redirected to home.
        return "/";
    }

    return hasLocale(path) ? path.substring(3) : path;
};

export const getBrowserLangPreference = () => {
    if (typeof navigator === `undefined`) {
        return FALLBACK_LANGUAGE;
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];

    if (AVAILABLE_LANG.indexOf(lang) === -1) {
        return FALLBACK_LANGUAGE;
    }

    return lang;
};
