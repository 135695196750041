import { navigate } from "gatsby";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { useCookies } from "react-cookie";
import { getBrowserLangPreference, hasLocale } from "./locale";
import theme from "./theme";
import { PageContext } from "./page-context";

export const RESPONSIVE_QUERIES = {
    mobile: `(max-width: ${theme.responsiveWidth})`,
};

export const useBreakpoint = (queries) => {
    const [queryMatch, setQueryMatch] = useState({});

    useEffect(() => {
        const mediaQueryLists = {};
        const keys = Object.keys(queries);

        // To check whether event listener is attached or not
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc, media) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {});
            // Setting state to the updated matches
            // when document either starts or stops matching a query
            setQueryMatch(updatedMatches);
        };

        if (window && window.matchMedia) {
            const matches = {};
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches;
                } else {
                    matches[media] = false;
                }
            });
            // Setting state to initial matching queries
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach((media) => {
                if (typeof queries[media] === "string") {
                    mediaQueryLists[media].addListener(handleQueryListener);
                }
            });
        }

        return () => {
            // If event listener is attached then remove it when deps change
            if (isAttached) {
                keys.forEach((media) => {
                    if (typeof queries[media] === "string") {
                        mediaQueryLists[media].removeListener(handleQueryListener);
                    }
                });
            }
        };
    }, [queries]);

    return queryMatch;
};

const isBrowser = typeof window !== "undefined";

export const useIsMobile = () => !!useBreakpoint(RESPONSIVE_QUERIES).mobile;

export const useLocationInitialization = (location) => {
    if (isBrowser) {
        const [cookies, setCookie] = useCookies();

        useEffect(() => {
            if (!hasLocale(location.pathname)) {
                navigate(
                    `/${getBrowserLangPreference()}${location.pathname}${location.search}${
                        location.hash
                    }`
                );
            } else {
                const params = new URLSearchParams(location.search);
                if (params.has("utm_campaign")) {
                    // once we are in an url with the language we can check if we can store utm params
                    const utmParams = [];
                    params.forEach((value, key) => {
                        if (key.startsWith("utm_")) {
                            utmParams.push([key, value]);
                        }
                    });
                    if (!cookies.ampl_utm_params) {
                        setCookie("ampl_utm_params", JSON.stringify(utmParams), { path: "/" });
                    }
                }
            }
        }, []);
    }
};

export const useAppLink = (path, forwardParameters, appendLocaleParameter) => {
    const [link, setLink] = useState("");
    const { locale } = useContext(PageContext);
    const { search } = useLocation();
    const [cookies] = useCookies();
    const newUrl = new URL(`${process.env.GATSBY_APP_SITE_URL}${path}`);
    let params = {};
    if (forwardParameters) {
        params = new URLSearchParams(search);
        if (cookies.ampl_utm_params) {
            const utmParamaters = cookies.ampl_utm_params;
            utmParamaters.forEach((p) => {
                if (p.length === 2) {
                    params.set(p[0], p[1]);
                }
            });
        }
    }
    if (appendLocaleParameter) {
        params.set("lang", locale);
    }
    useEffect(() => {
        params.forEach((value, key) => {
            newUrl.searchParams.append(key, value);
        });
        setLink(`${newUrl.toString()}`);
    }, []);

    return link;
};
