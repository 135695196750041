import React from "react";
import styled from "styled-components";

const Inner = styled.div`
    width: auto;
    margin: 0;
    // padding: 0 10px;
    position: relative;
    @media only screen and (min-width: ${({ theme }) => theme.contentWidth}) {
        max-width: ${({ theme }) => theme.contentWidth};
        margin: 0 auto;
        padding: 0;
    }
`;

export default styled(({ className, children }) => (
    <div className={className}>
        <Inner className="inner">{children}</Inner>
    </div>
))`
    width: auto;
`;
