import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import SvgIcon from "./svg-icon";
import Logo from "../../images/logo.inline.svg";
import CenterFrame from "./center-frame";
import { createLocaleLink } from "../../utils/locale";
import { useAppLink } from "../../utils/hooks";
import GoogleTag from "../../utils/google-tag";
import { UserContext } from "./user-zone";

const HeadButton = styled(
    ({
        title,
        to,
        className,
        onClick,
        target,
        isInternalLink = false,
        locale,
        pathname,
        eventName,
    }) => {
        const cn = `${className} ${pathname === to ? " selected" : ""}`;
        return isInternalLink ? (
            <Link
                onClick={onClick}
                className={cn}
                to={createLocaleLink(locale, to)}
                aria-label={title}
            >
                {title}
            </Link>
        ) : (
            <a
                onClick={() => {
                    GoogleTag.handle(eventName);
                }}
                className={cn}
                href={to}
                target={target}
                rel="noopener noreferrer"
                aria-label={title}
            >
                {title}
            </a>
        );
    }
)`
    a,
    button {
        cursor: pointer;
    }
`;

HeadButton.propTypes = {
    title: PropTypes.node.isRequired,
    to: PropTypes.string,
    className: PropTypes.string.isRequired,
    eventName: PropTypes.string,
    onClick: PropTypes.func,
    locale: PropTypes.string,
};

HeadButton.defaultProps = {
    to: "",
    selected: false,
    eventName: null,
    onClick: null,
};

const Header = ({ className, i18nData, locale }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const editLink = useAppLink(process.env.GATSBY_EDIT_PATH, true, true);
    const loginLink = useAppLink(process.env.GATSBY_SSO_LOGIN_PATH, true, true);
    const signupLink = useAppLink(process.env.GATSBY_SSO_SIGNUP_PATH, true, true);
    const toggleMenu = () => {
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    };

    return (
        <div className={className}>
            <div className={`header-bar ${menuOpen ? "open" : "closed"}`}>
                <CenterFrame className="header-centered">
                    <HeadButton
                        title={<Logo />}
                        className={`logo ${menuOpen ? "open" : ""}`}
                        locale={locale}
                        isInternalLink
                    />
                    <div className="main-desktop-links">
                        <HeadButton
                            className="head-button"
                            title={i18nData.home}
                            locale={locale}
                            isInternalLink
                        />
                        <HeadButton
                            className="head-button"
                            title={i18nData.pricing}
                            locale={locale}
                            to="pricing/"
                            isInternalLink
                        />
                        <HeadButton
                            className="head-button"
                            title={i18nData.blog}
                            isInternalLink
                            locale={locale}
                            to="blg/blog/"
                            eventName="click_blog"
                        />
                        <HeadButton
                            className="head-button"
                            title={i18nData.help}
                            to={`https://help.amplify.ooo/${locale}`}
                            eventName="click_help"
                            target="_blank"
                        />
                    </div>
                    <div className="right-actions">
                        <UserContext.Consumer>
                            {({ user, ready }) => {
                                if (!ready) {
                                    return <div className="loading-users" />;
                                }
                                if (user) {
                                    return (
                                        <HeadButton
                                            className="head-button is-button"
                                            title={i18nData.myMessages}
                                            to={editLink}
                                            eventName="click_my_ads"
                                        />
                                    );
                                }
                                return (
                                    <>
                                        <HeadButton
                                            className="head-button is-button"
                                            title={i18nData.login}
                                            to={loginLink}
                                            eventName="click_login"
                                        />
                                        <HeadButton
                                            className="head-button is-button"
                                            title={i18nData.signUp}
                                            to={signupLink}
                                            eventName="click_signup"
                                        />
                                    </>
                                );
                            }}
                        </UserContext.Consumer>
                        <button type="button" className="menu-button" onClick={toggleMenu}>
                            <SvgIcon icon={menuOpen ? "close" : "menu"} />
                        </button>
                    </div>
                </CenterFrame>
            </div>
            <div
                tabIndex="-1"
                role="presentation"
                className={`mobile-menu-zone ${menuOpen ? "open" : ""}`}
                onClick={toggleMenu}
            >
                <HeadButton
                    className="mobile-head-button"
                    title={i18nData.home}
                    locale={locale}
                    isInternalLink
                />
                <HeadButton
                    className="mobile-head-button"
                    title={i18nData.pricing}
                    locale={locale}
                    to="pricing/"
                    isInternalLink
                />
                <HeadButton
                    className="mobile-head-button"
                    title={i18nData.blog}
                    isInternalLink
                    locale={locale}
                    to="blg/blog/"
                    eventName="click_blog_mobile"
                />
                <HeadButton
                    className="mobile-head-button"
                    title={i18nData.help}
                    to={`https://help.amplify.ooo/${locale}`}
                    eventName="click_help_mobile"
                    target="_blank"
                />
                <hr />
                <UserContext.Consumer>
                    {({ user, ready }) => {
                        if (!ready) {
                            return null;
                        }

                        if (user) {
                            return (
                                <HeadButton
                                    className="mobile-head-button"
                                    title={i18nData.myMessages}
                                    to={editLink}
                                    eventName="click_my_ads_mobile"
                                />
                            );
                        }
                        return (
                            <>
                                <HeadButton
                                    className="mobile-head-button"
                                    title={i18nData.login}
                                    to={loginLink}
                                    eventName="click_login_mobile"
                                />
                                <HeadButton
                                    className="mobile-head-button"
                                    title={i18nData.signUp}
                                    to={signupLink}
                                    eventName="click_signup_mobile"
                                />
                            </>
                        );
                    }}
                </UserContext.Consumer>
            </div>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    i18nData: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
};

export const headerData = graphql`
    fragment HeaderData on DatoCmsI18n {
        help
        home
        pricing
        blog
        myMessages
        signUp
        login
    }
`;

const StyledHeader = styled(Header)`
    height: auto;
    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        margin: 1px 0;
        padding: 0;
    }
    svg {
        fill: white;
    }
    position: relative;
    // height: ${({ theme }) => theme.headerHeight};
    .header-bar {
        position: absolute;
        z-index: 1000;
        left: 0;
        right: 0;
        height: ${({ theme }) => theme.headerHeight};
        font-size: 0.9rem;
        &.closed {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        }
        .logo {
            display: flex;
            svg {
                width: 162px;
                height: 52px;
                fill: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
            }
        }
        .menu-button {
            display: none;
        }
        .main-desktop-links {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            > a {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                letter-spacing: 0.03em;
                text-align: center;
                color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
            }
            a:hover {
                text-shadow: 0 0 10px
                    ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.mainColor)};
                transition: all 0.3s ease-in-out;
            }
        }
        .loading-users {
            min-width: 100px;
        }
    }

    .header-centered {
        height: 100%;
        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            background-color: ${({ isHomepage }) => (isHomepage ? "none" : "#fff")};

            @media only screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
                grid-template-columns: 50% 0 50%;
                width: 100%;
                padding: 0;
                left: 0;
            }
        }
    }

    .mobile-menu-zone {
        // do not display mobile menu
        // it will be activated below
        display: none;
    }

    .right-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .head-button {
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 20px;
        color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
        height: ${({ theme }) => theme.headerHeight};
        line-height: ${({ theme }) => theme.headerHeight};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.selected {
            color: black;
            font-weight: bold;
        }
        &.is-button {
            height: 20px;
            line-height: 20px;
            border: 1px solid;
            border-color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
            color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
            padding: 10px 20px;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            white-space: nowrap;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
            border-radius: 30px;
        }
        &.is-button:hover {
            color: ${({ theme, isHomepage }) => (isHomepage ? theme.mainColor : "#fff")};
            background-color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.mainColor)};
            transition: all 0.3s ease-in-out;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
        .header-bar {
            height: ${({ theme }) => theme.headerHeightMobile};
            .logo {
                svg {
                    width: 120px;
                    height: 38px;
                    fill: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
                }
            }
            &.open {
                //background-color: ${({ theme }) => theme.mainColor};
                top: 0;
                left: 0;
            }

            .menu-button {
                text-decoration: none;
                display: inline-block;
                overflow: hidden;
                width: 40px;
                font-size: 28px;
                border: none;
                background-color: inherit;
                > div > svg {
                    fill: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.blackColor)};
                }
            }
            .right-actions {
                margin-right: 10px;
                padding-top: 1px;
                width: auto;
                .avatar-button,
                .avatar-cell {
                    height: 28px;
                    width: 28px;
                }
                a {
                    font-size: 100px;
                }
            }

            .head-button {
                display: none;
            }
        }

        .mobile-menu-zone {
            // use animation
            background-color: ${({ theme, isHomepage }) => (isHomepage ? theme.mainColor : "#fff")};
            display: block;
            max-height: 0;
            overflow: hidden;
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.05);
            width: 100vw;
            position: absolute;
            left: 0;
            top: 40px;
            z-index: 999999;
            &.open {
                max-height: 100vh;
            }

            .mobile-head-button {
                display: block;
                text-align: center;
                font-size: 14px;
                padding: 0;
                height: 40px;
                line-height: 40px;
                text-decoration: none;
                text-transform: uppercase;
                color: ${({ theme, isHomepage }) => (isHomepage ? "#fff" : theme.mainColor)};
                &:hover {
                    background-color: ${({ isHomepage }) =>
                        isHomepage ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"};
                    color: black;
                }
                &.selected {
                    color: black;
                    font-weight: bold;
                }
            }
        }
    }
`;

StyledHeader.propTypes = {
    isHomepage: PropTypes.bool,
};

StyledHeader.defaultProps = {
    isHomepage: false,
};

export default StyledHeader;
