import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import logger from "loglevel";

import Logo from "../../images/logo.inline.svg";
import Loading from "../../images/loading.inline.svg";
import Menu from "../../images/menu.inline.svg";
import Close from "../../images/close.inline.svg";
import Amplify from "../../images/amplify.inline.svg";
import LinkedIn from "../../images/linkedin.inline.svg";
import Facebook from "../../images/facebook.inline.svg";
import Twitter from "../../images/twitter.inline.svg";

const SvgIcon = ({ className, icon }) => {
    let svg = SvgIcon.IconMap[icon];
    if (!svg) {
        if (!global.TESTING) {
            logger.warn(`Icon with name '${icon}', does not exists`);
            svg = null;
        } else {
            svg = () => <svg>{icon}</svg>;
        }
    }
    return (
        <div className={`${className} svg-icon`}>{svg ? React.createElement(svg, {}) : null}</div>
    );
};

// load icon used by all apps
SvgIcon.IconMap = {
    logo: Logo,
    loading: Loading,
    menu: Menu,
    close: Close,
    amplify: Amplify,
    linkedin: LinkedIn,
    facebook: Facebook,
    twitter: Twitter,
};

SvgIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    // color: PropTypes.string,
    className: PropTypes.string.isRequired,
};

SvgIcon.defaultProps = {
    // color: null
};

SvgIcon.register = (map) => {
    Object.keys(map).forEach((k) => {
        if (SvgIcon.IconMap[k]) {
            logger.warn(`Icon with key ${k} is overridden`);
        }
        SvgIcon.IconMap[k] = map[k];
    });
};

export default styled(SvgIcon)`
    display: inline-flex;
    vertical-align: middle;
    justify-items: center;
    justify-content: center;
    align-items: center;
    svg {
        height: 1em;
        width: auto;
        fill: ${({ color }) => color};
    }
`;
