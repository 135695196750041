import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import { HelmetDatoCms } from "gatsby-source-datocms";

import Header from "../components/common/header";
import Footer from "../components/common/footer";
import theme from "../utils/theme";
import CookieBanner from "../components/common/cookie-banner";
import UserZone from "../components/common/user-zone";

const DefaultTemplate = (props) => {
    const { className, children, seoMetaTags, colored, i18nData, isHomepage, locale, pathname } =
        props;

    const datoCmsSite = useStaticQuery(graphql`
        query DataCmsSite {
            datoCmsSite {
                faviconMetaTags {
                    tags
                }
            }
        }
    `);

    return (
        <ThemeProvider theme={theme}>
            <HelmetDatoCms favicon={datoCmsSite.faviconMetaTags} seo={seoMetaTags} />
            <CookieBanner
                text={i18nData.cookieBannerText}
                link={i18nData.cookieBannerLink}
                locale={locale}
            />
            <div className={className}>
                <div className="content">
                    <UserZone>
                        <Header i18nData={i18nData} locale={locale} isHomepage={isHomepage} />
                    </UserZone>
                    {children}
                    <Footer
                        colored={colored || false}
                        i18nData={i18nData}
                        locale={locale}
                        pathname={pathname}
                    />
                </div>
            </div>
        </ThemeProvider>
    );
};
DefaultTemplate.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    seoMetaTags: PropTypes.shape({}).isRequired,
    colored: PropTypes.bool,
    i18nData: PropTypes.shape({
        cookieBannerText: PropTypes.string.isRequired,
        cookieBannerLink: PropTypes.string.isRequired,
    }).isRequired,
    isHomepage: PropTypes.bool,
    locale: PropTypes.string,
    pathname: PropTypes.string.isRequired,
};
DefaultTemplate.defaultProps = {
    colored: false,
    isHomepage: false,
    locale: "en",
};

export default styled(DefaultTemplate)`
    background-color: ${theme.backgroundColor};
    width: 100%;

    .content {
        position: absolute;
        overflow: hidden;
        // padding-top: ${({ isHomepage }) => (isHomepage ? 0 : theme.headerHeight)};
        width: 100%;
        overflow-x: hidden; // no auto, this will flick small views
        -webkit-overflow-scrolling: touch;
        @media screen and (max-width: ${theme.responsiveWidth}) {
            // padding-top: ${theme.headerHeightMobile};
        }
    }

    h4 {
        margin: 0;
        color: ${theme.grayColor};
        text-transform: uppercase;
    }

    .sub1 {
        color: white;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.02em;
        text-align: center;

        @media (min-width: ${theme.responsiveWidth}) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    .sub2 {
        color: ${theme.mainColor};
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;

        @media (min-width: ${theme.responsiveWidth}) {
            font-size: 18px;
            line-height: 21px;
        }
    }

    .cta {
        color: ${theme.mainColor};
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: center;

        @media screen and (min-width: ${theme.responsiveWidth}) {
            font-size: 17px;
        }
    }

    .t2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: ${theme.blackColor};
        text-align: center;
    }
`;
