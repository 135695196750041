export default {
    headerHeight: "80px",
    headerHeightMobile: "48px",
    backgroundColor: "#f2f2f2",
    placeholderColor: "#dddddd",
    overgroundColor: "white",
    mainColor: "#057CE5",
    altColor: "#0f75bf",
    bannerColor: "#101c24",
    greenColor: "#0f75bf",
    altHighColor: "#75a8cd",
    altLightColor: "#a8c8e6",
    altLighterColor: "#d8ecff",
    altBorder: "#04406c",
    blackColor: "#111111",
    grayColor: "#aaa",
    staffColor: "#d9ac09",
    staffBackgroundColor: "#fff9f0",
    lightGrayColor: "#ddd",
    contentWidth: "950px",
    responsiveWidth: "1150px",
    responsiveWidthFirst: "1024px",
    editorBackgroundColor: "#f2f2f2",
    mediaWidth: 256,
    mediaHeight: 256 / (16 / 9),
    mobileFirstWidth: "320px",
    mobileSecondWidth: "375px",
    mobileThirdWidth: "425px",
    mobileFourthWidth: "768px",
    normalLaptopWidth: "1024px",
    largeLaptopWidth1: "1150px",
    largeLaptopWidth1_1: "1151px",
    largeLaptopWidth: "1440px",
    largeMonitorWidth: "2560px",
    tabletFirstWidth: "768px",
    mobileViewWidth: "840px",
    mobileViewWidth_1: "839px",
    responsiveSpecialWindow: "1040px",
};
