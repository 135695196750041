import log from "loglevel";

const windowGlobal = typeof window !== "undefined" && window;

if (windowGlobal) {
  windowGlobal.intercom_booted = false;
  windowGlobal.intercom_disabled = true;
}

function buildData(identity) {
  if (identity) {
    return {
      email: identity.email,
      user_id: identity.id,
      name: `${identity.firstName} ${identity.lastName}`,
      language_override: identity.lang,
    };
  }
  return {};
}

function enable(value) {
  windowGlobal.intercom_disabled = !value;
  if (windowGlobal.Intercom && windowGlobal.intercom_booted) {
    windowGlobal.Intercom("update", {
      hide_default_launcher: !value,
    });
  }
}

function boot(identity) {
  if (windowGlobal.Intercom) {
    log.info("[INTERCOM] Booting");
    if (identity && !identity.intercomUserHash) {
      log.warning(
        "[INTERCOM] Unable to boot, identity does not provide a user_hash"
      );
      return;
    }
    const hash = identity ? { user_hash: identity.intercomUserHash } : {};
    windowGlobal.Intercom("boot", {
      ...buildData(identity),
      hide_default_launcher: windowGlobal.intercom_disabled,
      app_id: process.env.GATSBY_INTERCOM_APP_ID,
      ...hash,
    });
    windowGlobal.intercom_booted = true;
    log.info(
      `[INTERCOM] booted with visibility: ${!windowGlobal.intercom_disabled}`
    );
    enable(!windowGlobal.intercom_disabled);
  } else {
    log.info("[INTERCOM NOT SET] Unable to set identity");
  }
}

export default {
  boot,
  enable,
};
