import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useCookieNamed } from "../../utils/cookies";
import CenterFrame from "./center-frame";
import SvgIcon from "./svg-icon";
import { createLocaleLink } from "../../utils/locale";

function CookieBanner({ className, hidden, text, link, locale }) {
    const [cookie, setCookie] = useCookieNamed("cookie-consent");
    const path = createLocaleLink(locale, "use-of-cookies");

    function acceptCookie() {
        setCookie("read");
    }

    const displayCookie = !hidden && cookie !== "read";

    return displayCookie ? (
        <div className={className}>
            <CenterFrame>
                <div className="banner">
                    <div className="text">
                        {text}
                        <Link to={path}> {link}</Link>
                    </div>
                    <button
                        type="button"
                        onClick={() => acceptCookie()}
                        aria-label="Close Cookie Banner"
                    >
                        <SvgIcon icon="close" />
                    </button>
                </div>
            </CenterFrame>
        </div>
    ) : (
        <div style={{ display: "none" }} />
    );
}

CookieBanner.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
};

CookieBanner.defaultProps = {
    hidden: false,
};

export const cookieBannerData = graphql`
    fragment CookieBannerData on DatoCmsI18n {
        cookieBannerText
        cookieBannerLink
    }
`;

export default styled(CookieBanner)`
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 10px;
    height: auto;
    z-index: 2147484002;

    button {
        padding: 0;
        border: none;
        font: inherit;
        color: ${({ theme }) => theme.altColor};
        svg {
            fill: white;
        }
        background-color: transparent;
        /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
        cursor: pointer;
        &:hover {
            svg {
                fill: white;
            }
        }
        margin: 20px;
    }
    .banner {
        background-color: ${({ theme }) => theme.bannerColor};
        color: white;
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0 -10px;
        box-sizing: border-box;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        .text {
            flex-grow: 1;
            margin-left: 20px;
            line-height: 1.2em;
        }
        a,
        a:focus,
        a:link,
        a:active {
            color: ${({ theme }) => theme.overgroundColor};
        }
    }

    @media only screen and (max-width: ${({ theme }) => theme.responsiveWidth}) {
        // mobile version
        height: auto;
        bottom: 0;
        left: 0;
        right: 0;
        .banner {
            height: auto;
            padding: 10px 20px;
            border-radius: 0;
            margin: 0 -30px;
        }
    }
`;
