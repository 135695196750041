import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import fetch from "cross-fetch";
import log from "loglevel";

import { useCookieNamed } from "../../utils/cookies";
import Intercom from "../../utils/intercom";

const isBrowser = typeof window !== "undefined";

export const UserContext = createContext({
    user: null,
    ready: false,
});

const STORAGE_KEY = "_identity";
const loadFromStore = () => {
    const identity = isBrowser ? localStorage.getItem(STORAGE_KEY) : null;
    if (identity) {
        try {
            return JSON.parse(identity);
        } catch (ex) {
            log.error("Unable to load identity from localStorage");
        }
    }
    return {
        user: null,
        jwttoken: null,
    };
};

const saveToStorage = (jwttoken, user) => {
    const data = { jwttoken, user };
    if (isBrowser) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    }
    return data;
};

function useJwtToken() {
    const [jwtToken] = useCookieNamed("jwttoken");
    const [identityValue, setIdentityValue] = useState(loadFromStore());
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            if (identityValue.jwtToken === jwtToken) {
                setReady(true);
                return;
            }
            try {
                const url = process.env.GATSBY_EXTERNAL_USER_GRAPHQL;
                if (url) {
                    const response = await fetch(url, {
                        method: "POST",
                        cache: "no-cache",
                        credentials: "include",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        body: JSON.stringify({
                            query: `
                                  query identity {
                                    identity {
                                      id
                                      firstName
                                      lastName
                                      email
                                      intercomUserHash
                                      lang
                                    }
                                  }
                            `,
                        }),
                    });
                    const {
                        data: { identity },
                    } = await response.json();
                    Intercom.boot(identity);
                    const newIdentity = saveToStorage(jwtToken, identity);
                    setIdentityValue(newIdentity);
                } else {
                    log.error("GATSBY_EXTERNAL_USER_GRAPHQL is not set in the env file");
                }
            } catch (error) {
                log.error(`Error while checking user identity, reason=${error}`);
            } finally {
                setReady(true);
            }
        };

        fetchUserData();
    }, [jwtToken]);
    return [ready, identityValue.user];
}

const UserZone = ({ children }) => {
    const [cookie] = useCookieNamed("cookie-consent");
    const [intercomSessionId, setIntercomSessionId] = useCookieNamed(
        `intercom-session-${process.env.GATSBY_INTERCOM_APP_ID}`
    );
    const [ready, user] = useJwtToken();

    useEffect(() => {
        if (cookie === "read") {
            Intercom.enable(true);
        }
    }, [cookie]);

    useEffect(() => {
        if (!user && intercomSessionId) {
            setIntercomSessionId(null);
        }
    }, [user, intercomSessionId]);

    return <UserContext.Provider value={{ user, ready }}>{children}</UserContext.Provider>;
};

UserZone.propTypes = {
    children: PropTypes.node,
};

UserZone.defaultProps = {
    children: null,
};

export default UserZone;
