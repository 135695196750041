import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql, Link, navigate } from "gatsby";

import CenterFrame from "./center-frame";
import { createLocaleLink, getPathWithoutLocale } from "../../utils/locale";
import Logo from "../../images/logo.inline.svg";
import GoogleTag from "../../utils/google-tag";
import SvgIcon from "./svg-icon";

const windowGlobal = typeof window !== "undefined" && window;

const FooterButton = ({ title, to, href, target, eventName }) =>
    href ? (
        <a
            href={href}
            target={target}
            onClick={() => GoogleTag.handle(eventName)}
            aria-label={title}
        >
            {title}
        </a>
    ) : (
        <Link to={to} aria-label={title}>
            {title}
        </Link>
    );

FooterButton.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    eventName: PropTypes.string,
};

FooterButton.defaultProps = {
    href: null,
    to: null,
    target: null,
    eventName: null,
};

const Title = styled.h2`
    width: 183px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #fff;

    @media only screen and (min-width: ${({ theme }) => theme.mobileFirstWidth}) {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        height: 27px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.mobileViewWidth}) {
        margin-bottom: 10px;
        font-size: 25px;
        line-height: 63px;
        height: 58px;
    }
`;
const CompanyLogo = styled.svg`
    margin-left: -20px;
    fill: #fff;
    width: 183px;
    height: 58px;
`;

const TOP_HEIGHT = "50px";
const FooterStyle = styled.div`
    position: relative;
    &:after {
        content: " ";
        position: absolute;
        top: -${TOP_HEIGHT};
        height: ${TOP_HEIGHT};
        left: 0;
        right: 0;
        background-image: url(/images/footer-top-background.svg);
        background-repeat: repeat-x;
        background-position: center bottom;
        background-size: 100vw ${TOP_HEIGHT};
    }
`;

const Footer = ({ className, i18nData, locale, pathname }) => {
    const LANG_VALUES = [
        { value: "en", label: "English" },
        { value: "fr", label: "Français" },
        { value: "de", label: "Deutsch" },
    ];

    const handleChangeLang = (value) => {
        const path = getPathWithoutLocale(pathname);

        GoogleTag.handle(`change_locale_to_${value}`);

        navigate(`/${value}${path}`);
    };

    return (
        <FooterStyle>
            <CenterFrame className={className}>
                <div className="footer">
                    <div className="footer-column">
                        <CompanyLogo>
                            <Logo width="183px" height="58px" />
                        </CompanyLogo>
                        <ul>
                            <li className="footer-item">
                                <FooterButton
                                    title={i18nData.footerAbout}
                                    to={createLocaleLink(locale, "about/")}
                                />
                            </li>
                            <li className="footer-item">
                                <FooterButton
                                    title={i18nData.footerPrivacyPolicy}
                                    target="_blank"
                                    href={`/${locale}/pdfs/privacy-notice.pdf`}
                                    eventName={`view_privacy_notice_${locale}`}
                                />
                            </li>
                            <li className="footer-item">
                                <FooterButton
                                    title={i18nData.footerTermsOfService}
                                    target="_blank"
                                    href={`/${locale}/pdfs/terms-of-use.pdf`}
                                    eventName={`view_terms_of_use_${locale}`}
                                />
                            </li>
                            <li className="footer-item">
                                <FooterButton
                                    title={i18nData.footerUseOfCookies}
                                    to={createLocaleLink(locale, "use-of-cookies/")}
                                />
                            </li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <Title>{i18nData.footerContact}</Title>
                        <ul>
                            <li className="footer-item">
                                <FooterButton
                                    href="mailto:info@amplify.ooo"
                                    title="info@amplify.ooo"
                                    eventName="mailto"
                                />
                            </li>
                            <li>Amplify SA, Switzerland</li>
                            <li>
                                <div className="footer-icons">
                                    <a
                                        href="https://web.facebook.com/amplify.ooo/?_rdc=1&_rdr"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <SvgIcon icon="facebook" />
                                    </a>
                                    <a
                                        href="https://twitter.com/amplify_ooo"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <SvgIcon icon="twitter" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/weamplify"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <SvgIcon icon="linkedin" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <Title>{i18nData.footerLanguage}</Title>
                        <ul>
                            {LANG_VALUES.map((lang) => (
                                <li key={`locale-${lang.value}`} className="footer-item">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value={windowGlobal.CSRF}
                                        />
                                        <input type="hidden" name="lang" value={lang.value} />
                                        <span className="language">
                                            <input
                                                onClick={() => handleChangeLang(lang.value)}
                                                type="button"
                                                value={lang.label}
                                                disabled={lang.value === locale}
                                            />
                                            {lang.value === locale && (
                                                <span>&nbsp;( {i18nData.footerCurrentLang} )</span>
                                            )}
                                        </span>
                                    </form>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </CenterFrame>
        </FooterStyle>
    );
};

Footer.propTypes = {
    className: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    i18nData: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
};

export const footerData = graphql`
    fragment FooterData on DatoCmsI18n {
        footerAbout
        footerCompany
        footerContact
        footerCurrentLang
        footerHowItWorks
        footerLanguage
        footerMessages
        footerPrivacyPolicy
        footerTermsOfService
        footerUseOfCookies
    }
`;

export default styled(Footer)`
    background: ${({ theme }) => theme.mainColor};
    .inner {
        background-color: ${({ theme }) => theme.mainColor};
    }
    .footer {
        color: #fff;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.02em;

        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .footer-column {
            min-width: 30%;
            &:not(:last-child) {
                margin-left: 30px;
                flex-grow: 0;
            }
            &:last-child {
                flex-grow: 1;
            }
            ul {
                text-align: left;
                padding: 10px 0 40px 0;
                li {
                    list-style: none;
                    line-height: 30px;
                    &.footer-item {
                        margin-bottom: 5px;
                    }
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                    a:hover,
                    input[type="button"]:hover {
                        font-weight: bold;
                        -webkit-transition: all 0.2s ease-in-out;
                        -moz-transition: all 0.2s ease-in-out;
                        -o-transition: all 0.2s ease-in-out;
                        transition: all 0.2s ease-in-out;
                    }
                    input[type="button"] {
                        background: none;
                        border: none;
                        color: #fff;
                        text-decoration: none;
                        cursor: pointer;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        font: inherit;
                        vertical-align: baseline;

                        &:disabled {
                            cursor: inherit;
                        }
                    }
                }
                .footer-icons {
                    height: 60px;
                    svg {
                        fill: #fff;
                        width: 35px;
                        height: 35px;
                        margin: 15px 15px 15px 0;
                        cursor: pointer;
                    }
                    svg:hover {
                        margin-top: 14px;
                        overflow: hidden;
                        transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -webkit-transform: scale (1.2);
                        -moz-transform: scale(1.2);
                        -o-transform: scale(1.2);
                        -webkit-transition: all 0.2s ease-in-out;
                        -moz-transition: all 0.2s ease-in-out;
                        -o-transition: all 0.2s ease-in-out;
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
        }
        @media only screen and (max-width: ${({ theme }) => theme.mobileViewWidth}) {
            padding: 20px 10px;
            .footer-column {
                min-width: 30%;
                &:not(:last-child) {
                    margin-left: 0;
                    flex-grow: 0;
                }
            }
        }
        @media only screen and (max-width: ${({ theme }) => theme.mobileViewWidth_1}) {
            .footer-column {
                min-width: 100%;

                ul {
                    text-align: center;
                    .footer-icons {
                        position: absolute;
                        display: flex;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        svg {
                            margin: 10px;
                        }
                    }

                    padding: 0 0 40px 0;

                    li {
                        line-height: 24px;

                        text-align: center;
                        letter-spacing: 0.01em;
                    }
                }
            }
            .footer-column:last-child {
                padding-bottom: 40px;
            }

            font-size: 14px;
            line-height: 24px;

            text-align: center;
            letter-spacing: 0.01em;
        }
    }
    @media only screen and (max-width: ${({ theme }) => theme.mobileViewWidth_1}) {
        h2 {
            width: 100%;
            text-align: center;
        }
        .footer-column {
            > svg {
                display: flex;
                margin: auto;
                padding-right: 20px;
            }
        }
    }
`;
